import { template as template_87f9e33545534543aa5d911ccdc94a39 } from "@ember/template-compiler";
const SidebarSectionMessage = template_87f9e33545534543aa5d911ccdc94a39(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
