import { template as template_9470c5f3d89a479dab60bc0647a757b2 } from "@ember/template-compiler";
const FKControlMenuContainer = template_9470c5f3d89a479dab60bc0647a757b2(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
